<template>
  <div>
    <top-broker />
  </div>
</template>

<script>
import TopBroker from '@/views/shared/TopBrokers'

export default {
  components: {
    TopBroker
  },
};
</script>
